// Probably needs to be deleted
import { storyblokEditable } from '@storyblok/react';

import { ArticleFeatureCard } from 'components/common-n4/article-feature-card/article-feature-card';
import { useApiData } from 'hooks/use_api_data';
import logger from 'lib/logger';

const log = logger({ category: 'n4/home/hearing-aid' });

const HomeHearingAid = ({ blok, story, ...props }) => {
  const { models } = useApiData().api;
  log.debug('blok: %o', { blok, props });
  const model = models[blok.form_factor];
  if (!model || model.notFound) {
    // This shouldn't happen in production
    console.error('Missing data for form factor %s', blok.form_factor);
    return <div>Missing {blok.form_factor}</div>;
  }
  return (
    <ArticleFeatureCard
      image={model.image}
      title={model.name}
      body={model.description}
      url={`/hearing-aids/${model.release_slug}`}
      score={{
        amount: model.score,
      }}
      {...storyblokEditable(blok)}
    />
  );
};

export default HomeHearingAid;
