import { motion, useScroll, useTransform } from 'framer-motion';

import { useWindowDimensions } from 'hooks/use_window_dimensions';

import styles from './scroll-to-explore.module.scss';

export const ScrollToExplore = () => {
  const { height } = useWindowDimensions(true);
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, height], [1, 0.2]);

  const handleScroll = () => {
    const exploreSection = document.getElementById('explore');
    if (exploreSection) {
      exploreSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <motion.div initial={{ opacity: 1 }} style={{ opacity }}>
      <div onClick={handleScroll} className={styles.scrollToExplore}>
        <i className={styles.icon}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img alt="Scroll To Explore Icon" src="/images/home/scroll-to-explore.svg" />
        </i>
        <span>Scroll to explore</span>
      </div>
    </motion.div>
  );
};
